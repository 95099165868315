import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-tooltip';
import ResponsivePagination from 'react-responsive-pagination';
import 'bootstrap/dist/css/bootstrap.css';

const tableHeaderList = [
    {
        colHeadLabel: 'GENE',
        description: 'Gene symbol derived from VEP',
    },
    {
        colHeadLabel: 'CHROM',
        description: 'Chromosome',
    },
    {
        colHeadLabel: 'POS',
        description: 'Position on the chromosome',
    },
    {
        colHeadLabel: 'REF',
        description: 'Reference allele',
    },
    {
        colHeadLabel: 'ALT',
        description: 'Alternate alleles',
    },
    {
        colHeadLabel: 'TYPE',
        description: 'Variant type',
    },
    {
        colHeadLabel: 'AF',
        description: 'Alternate allele frequency in the GREGoR dataset',
    },
    {
        colHeadLabel: 'AN',
        description: 'Total number of alleles in called genotypes',
    },
    {
        colHeadLabel: 'AC_HET',
        description: 'Heterozygote allele count',
    },
    {
        colHeadLabel: 'AC_HOM',
        description: 'Homozygote allele count',
    },
    {
        colHeadLabel: 'AC',
        description: 'Allele count',
    },
];

function SearchDataTable({ data }) {
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(50);
    const totalPages = Math.ceil(data.length / recordsPerPage);

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = data.slice(indexOfFirstRecord, indexOfLastRecord);

    function handlePageChange(page) {
        setCurrentPage(page);
    }

    return (
        <div className="table-responsive search-data-table mt-3 mb-5">
            <div className="pagination-container d-flex align-items-center justify-content-between">
                <div className="records-per-page-select d-flex align-items-center mb-3">
                    <span className="records-select-label">Display records per page:</span>
                    <select
                        className="form-select records-select"
                        value={recordsPerPage}
                        onChange={(e) => setRecordsPerPage(e.target.value)}
                    >
                        <option>50</option>
                        <option>100</option>
                        <option>200</option>
                        <option>400</option>
                    </select>
                </div>
                <ResponsivePagination
                    total={totalPages}
                    current={currentPage}
                    maxWidth={800}
                    extraClassName="justify-content-end"
                    onPageChange={page => handlePageChange(page)}
                />
            </div>
            <table className="table table-bordered table-hover mb-5">
                <thead className="table-dark">
                    <tr>
                        {tableHeaderList.map((item) => {
                            return (
                                <th key={item.colHeadLabel} scope="col" className={`col-head-${item.colHeadLabel} text-center`}>
                                    {item.colHeadLabel}
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    {currentRecords.map((row) => (
                        <tr key={`${row.chrom}-${row.pos}-${row.ref}-${row.alt}-${row.gene}`}>
                            <td>
                                {row.gene ? row.gene : '-'}
                            </td>
                            <td>
                                {row.chrom}
                            </td>
                            <td>
                                {row.pos}
                            </td>
                            <td className="text-break">
                                {row.ref}
                            </td>
                            <td className="text-break">
                                {row.alt}
                            </td>
                            <td>
                                {row.type ? row.type : '-'}
                            </td>
                            <td className="text-break">
                                {row.af ? row.af : '-'}
                            </td>
                            <td className="text-break">
                                {row.an ? row.an : '-'}
                            </td>
                            <td>
                                {row.ac_het? row.ac_het : '-'}
                            </td>
                            <td>
                                {row.ac_hom ? row.ac_hom : '-'}
                            </td>
                            <td>
                                {row.ac ? row.ac : '-'}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {tableHeaderList.map((item) => {
                return (
                    <Tooltip key={`tooltip-${item.colHeadLabel}`} anchorSelect={`.col-head-${item.colHeadLabel}`} place="bottom">
                        {item.description}
                    </Tooltip>
                );
            })}
        </div>
    );
}

SearchDataTable.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            CHROM: PropTypes.string,
            POS: PropTypes.number,
            REF: PropTypes.string,
            ALT: PropTypes.string,
            AC: PropTypes.number,
            AF: PropTypes.number,
            AN: PropTypes.number,
            AC_HET: PropTypes.number,
            AC_HOM: PropTypes.number,
            TYPE: PropTypes.string,
            GENE: PropTypes.string,
        })
    ),
};

SearchDataTable.defaultProps = {
    data: [],
};

export default SearchDataTable;
